import React from "react"
import { FaGraduationCap, FaPenSquare, FaBook } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const getImage = graphql`
  {
    fluid: file(relativePath: { eq: "vovotoqueiro.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(getImage)
  return (
    <Layout>
      <SEO title="Início" />
      <div className="showcase">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-5 text-center font-weight-bold">
                <em>Ponto de encontro de velhos (e novos) Geeks.</em>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 d-none d-lg-block">
              <div className="d-flex flex-row">
                <div className="p-4">
                  <FaGraduationCap />
                </div>
                <div className="p-4 font-weight-bolder">
                  <p>
                    Tutoriais e cursos em vídeo de qualidade para você aprimorar
                    suas técnicas de Desenvolvimento Web, com as ferramentas e
                    "frameworks" mais modernos do mercado.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="p-4">
                  <FaPenSquare />
                </div>
                <div className="p-4 font-weight-bolder">
                  <p>
                    Blog de artigos técnicos focalizando minhas principais áreas
                    de interesse: programação, eletrônica e desenvolvimento para
                    a Web.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div className="p-4">
                  <FaBook />
                </div>
                <div className="p-4 font-weight-bolder">
                  <p>
                    Meus ensaios literários, a maioria deles de conteúdo
                    político e crônicas do cotidiano, para desobliterar meu
                    fígado...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mt-4">
                <div className="card bg-info text-center card-form">
                  <div className="card-body">
                    <h3>Todo o conteúdo inteiramente disponível</h3>
                    <p>Acesse já</p>
                    <a
                      href="/cursos"
                      className="btn btn-outline-light btn-block"
                    >
                      Cursos & Tutoriais
                    </a>
                    <a
                      href="/tecnicos/pagina/1"
                      className="btn btn-outline-light btn-block"
                    >
                      Artigos Técnicos
                    </a>
                    <a
                      href="/cronicas/pagina/1"
                      className="btn btn-outline-light btn-block"
                    >
                      Crônicas & Ensaios
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-lg-12 d-none d-lg-block">
              <div className="card card-vovo">
                <Img fluid={data.fluid.childImageSharp.fluid} />
                <div className="card-body">
                  <p className="card-text text-center">
                    Acompanhe minhas sensacionais aventuras motociclísticas
                  </p>
                  <a
                    href="/vovotoqueiro"
                    className="btn btn-outline-light btn-block"
                  >
                    Vovotoqueiro
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
